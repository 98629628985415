// Device breakpoints, use Bootstrap naming convention
$breakpoints: (
  xl: 1440px,
  lg: 1280px,
  md: 960px,
  sm: 640px,
  xs: 480px,
);

// Get breakpoint easier
// @param {Number} $number - should be string or number with unit
// @return {Number}
@function getValue($size) {
  $width: null;
  $input-type: type-of($size);

  @if $input-type == string {
    @if map-has-key($breakpoints, $size) {
      $width: map-get($breakpoints, $size);
    } @else {
      @error "Cannot find '#{$size}' in $breakpoints!";
    }
  } @else if $input-type == number {
    @if not unitless($size) {
      $width: $size;
      @warn "You added a Tweakpoint at `#{$size}.";
    } @else {
      @error "#{$size} must have unit!";
    }
  } @else {
    @error "You must put a string or number inside max-at, but '#{$size}' is a #{$input-type}!";
  }

  @return $width;
}

@mixin max-at($size) {
  @media screen and (max-width: getValue($size)) {
    @content;
  }
}

@mixin min-at($size) {
  @media screen and (min-width: #{getValue($size) + 1px}) {
    @content;
  }
}
