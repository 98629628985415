.album-year {
  font-size: $font-xs;
  font-family: $title-font;
  color: $spicy-red;

  &::before, &::after {
    content: '';
    display: block;
    width: 8px;
    height: 1px;
    background-color: $spicy-red;
    margin: 0 12px;
  }

  display: flex;
  align-items: center;
}

.album-header {
  text-align: center;

  > h2 {
    font-size: $font-md;
    letter-spacing: 0.075em;
  }

  > p {
    font-size: $font-sm;
    font-family: $title-font;
    opacity: 0.75;
  }
}

.album-cover {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;

  @include ghost-float;
}

.album-thumb {
  max-width: 240px;
  margin: 0 auto;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @include gutter(bottom, 8px);

  position: relative;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    right: -16px;
    bottom: -8px;
    left: -16px;
    background-color: $light-grey;
    z-index: -1;
    border-radius: 4px;

    // Initial style
    opacity: 0;
    transition: opacity 0.15s ease-in;
  }

  &:hover{
    &::after {
      opacity: 0.5;
      transition: opacity 0.25s ease-out;
    }
  }
}

.album-item {
  padding: 0 16px;
  cursor: pointer;
  position: relative;
  // 2 in a row
  flex-basis: calc(50%);

  @include min-at(xs) {
    // 3 in a row
    flex-basis: calc(33%);
  }

  @include min-at(md) {
    // 4 in a row
    flex-basis: calc(25%);
  }
}

.album-item-list {
  display: flex;
  flex-flow: row wrap;

  @include gutter(bottom, 32px);
}

/* Album detail items
 * ------------------------ */

.disc-tab {
  font-family: $title-font;
  color: $quite-black;
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
  cursor: pointer;

  &.active {
    opacity: 1;
    cursor: default;
  }

  &:hover {
    opacity: 1;
  transition: opacity 0.25s ease-out;
  }
}

.track-number {
  opacity: 0.65;
}

.track-title {

}

.album-track-item {
  width: calc(50% - 16px);
  margin-right: 16px;
  font-family: $title-font;
  @include gutter(right, 12px);

  display: flex;
  align-items: baseline;
}


.album-track-list {
  display: none;
  width: 100%;

  max-height: 200px;

  &.active {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    align-content: flex-start;

    @include gutter(bottom, 4px);
  }
}

.disc-tab-wrapper {
  @include gutter(right, 16px);
}

.album-detail-disc-wrapper {
  @include gutter(bottom, 8px);
}

.award-item-event {
  flex-basis: 40%;

  display: flex;
  align-items: flex-start;
  @include gutter(right, 8px);

  // style tweaks
  > svg {
    opacity: 0.75; // make the icon less obvious than text
    margin-top: 2px; // visually centered
    flex: none;
  }
}

.award-item-year {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
}

.award-category-name {

}

.award-category-result {
  margin-left: auto;
  flex: none;

  display: flex;
  align-items: center;
  @include gutter(right, 4px);

  > svg {
    opacity: 0.75;
  }
}

.award-category-item {
  display: flex;
  align-items: flex-start;

  @include gutter(right, 4px);
}

.award-category-list {
  flex-basis: 40%;

  display: flex;
  flex-flow: column nowrap;
  @include gutter(bottom, 8px);
}

.album-detail-award-item {
  display: flex;
  align-items: flex-start;
}

.album-detail-award-list {
  @include gutter(bottom, 16px);
}

.album-detail-intro,
.album-detail-disc,
.album-detail-rest {
  @include min-at(sm) {
    flex-basis: calc(50% - 32px);
  }
  @include min-at(lg) {
    flex-basis: calc(33.3% - 32px);
  }
}

.album-detail-intro,
.album-detail-disc,
.album-detail-award-section,
.album-detail-platform {
  @include gutter(bottom, 16px);
}

.album-detail-item {
  padding: 16px;
  position: relative;

  // The triangle
  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 10px;
    background-color: $light-grey;
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);

    position: absolute;
    top: 0;
    left: var(--pointer-left);
    transform: translate(-50%, -90%);
  }

  display: none;

  &.active {
    display: block;

    @include min-at(sm) {
      display: flex;
      flex-flow: row wrap;
      @include gutter(right, 32px);
    }
  }
}


.album-detail-item,
.album-detail-rest {
  @include gutter(bottom, 24px);
}

.album-detail-list {
  position: absolute;
  background-color: $light-grey;
  left: 0;
  right: 0;
  // Need to decide `top`! Controled by JavaScript
}

.album-list-section {
  width: calc(100% - 16px);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  background-color: $very-white;
  padding: 24px 16px;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -2px; // Prevent rendering glich
    height: 4px;
    background-image: $sunset-gradient;
  }
}

.album-detail-platform-list {
  @include gutter(bottom, 8px);
}

.purchase-link {
  font-family: $title-font;
  display: flex;
  align-items: center;
  @include gutter(right, 8px);

  opacity: 0.75;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.9;
  }
}
