$font-xxxl: 40px;
$font-xxl: 32px;
$font-xl: 24px;
$font-lg: 18px;
$font-md: 16px;
$font-sm: 14px;
$font-xs: 12px;
$font-xxs: 11px;

$title-font: 'Oswald', 'Microsoft JhengHei', '微軟正黑體', 'Roboto', sans-serif;
$body-font: 'San Fransisco', 'Microsoft JhengHei', '微軟正黑體', 'Helvetica Neue', sans-serif;


/* Implementation
  ========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Oswald:300,500,700');

body {
  font-family: $body-font;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: 0.05em;
	margin: 0;
}

p {
  margin: 0;
  line-height: 1.8;
}

a, a:hover, a:active, a:visited, a:focus {
	text-decoration: none;
  color: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}
