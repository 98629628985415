.section-title {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 64px auto;

  &::before {
    content: '';
    display: block;
    max-width: 400px;
    width: 80%;
    height: 8px;
    opacity: 0.6;
    background-image: linear-gradient(
      to right,
      $quite-black 0%,
      $quite-black 20%,
      transparent 20%
     );
    background-size: 16px 8px;
    background-repeat: repeat-x;
    transform: skew(-24deg);

    margin-bottom: 48px;
  }

  h2 {
    font-family: $title-font;
    font-size: $font-lg;
    margin-bottom: 8px;
    letter-spacing: 0.1em;

    @include min-at(lg) {
      font-size: $font-xl;
    }
  }

  p {
    @extend .nav-tagline;
    transform: translateY(-6px);
  }
}
