/* Layout control
  ========================================================================== */

@mixin gutter($direction, $distance) {
	// If direction === right or bottom
	@if $direction == right or $direction == bottom {
		> * {
			margin-#{$direction}: $distance;

			&:last-child {
				margin-#{$direction}: 0;
			}
		}
	} @else if $direction == left or $direction == top {
		// If direction === left & top
		> * {
			margin-#{$direction}: $distance;

			&:first-child {
				margin-#{$direction}: 0;
			}
		}
	}
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin position-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin background-cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

/* Shadow
  ========================================================================== */

// Diffused shadow
@mixin diffused-shadow($distance: 8px, $color: #000, $opacity: 0.3) {
  @if type-of($distance) != number {
    @error "#{$distance} is not a number!";
  }
  @if type-of($color) != color {
    @error "#{$color} is not a color";
  }
  @if type-of($opacity) != number {
    @error "#{$opacity} is not a number";
  }
  box-shadow: 0 $distance #{1.6 * $distance} -#{0.6 * $distance} rgba($color, $opacity);
}

// Ghost
@mixin ghost-float($d: 8px) {
  box-shadow:
    $d #{0.8 * $d} #{4.8 * $d} rgba(39,44,49,.06),
    1px #{0.4 * $d} $d rgba(39,44,49,.03);
	transition:
		transform 0.5s ease-out,
		box-shadow 0.5s ease-out;

	&:hover {
    box-shadow:
      0 0 1px rgba(39,44,49,.1),
      0 #{0.4 * $d} #{2 * $d} rgba(39,44,49,.07);
		transform: translate3D(0, -1px, 0);
		transition:
			transform 0.3s ease-in,
			box-shadow 0.3s ease-in;
	}
}


/* Button
  ========================================================================== */

@mixin icon-button($size: 24px) {
	width: $size;
	height: $size;
	background-color: transparent;
	padding: 0;
	border: 0px;
	cursor: pointer;
	flex: none;
  @include flex-center;

  &:focus {
    outline: none;
  }
}
