.news-thumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  @include position-center;
}

.news-thumb-wrapper {
  width: 100%;
  flex-basis: 40%;
  padding-top: 28%;
  position: relative;
  overflow: hidden;

  // Remove the white margin between this wrapper & the list item container
  // (might because of browser render problem)
  // Slightly scale it up to fill the margin
  transform: scale(1.0075);


  @include min-at(sm) {
    padding-top: 62.5%;
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 90%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 90%, 100% 0);
  }

  .highlighted > & {
    padding-top: 48%;
    -webkit-clip-path: polygon(0 0, 0 90%, 100% 100%, 100% 0);
    clip-path: polygon(0 0, 0 90%, 100% 100%, 100% 0);

    @include min-at(sm) {
      padding-top: 32%;
      -webkit-clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}


.news-title {
  font-size: $font-md;
  line-height: 1.5;

  @include min-at(sm) {
    text-align: center;
    font-weight: normal;
    line-height: 1.6;
  }

  @include min-at(lg) {
    font-size: $font-lg;
    line-height: 1.8;
  }

  .highlighted & {
    font-size: $font-lg;
    text-align: left;

    @include min-at(sm) {
      font-size: $font-xl;
    }

    @include min-at(lg) {
      font-size: $font-xxl;
    }
  }
}

.news-date {
  opacity: 0.8;
  font-size: $font-sm;

  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $spicy-red;
    margin-right: 8px;
  }

  @include min-at(sm) {
    justify-content: center;

    .highlighted & {
      justify-content: flex-start;
    }
  }
}

.news-summary {
  opacity: 0.65;

  @include min-at(md) {
    font-size: $font-md;
  }
}

.news-header {
  @include gutter(bottom, 8px);
}

.news-info {
  padding: 0 8px;
  flex-basis: 60%;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  @include gutter(bottom, 16px);

  @include min-at(md) {
    padding: 0 16px;
  }
}

.news-card {
  width: 100%;
  background-color: $very-white;
  padding: 16px 8px;
  border-bottom: 1px solid $real-grey;
  flex-basis: 100%;
  overflow: hidden;

  display: flex;
  align-items: flex-start;

  @include gutter(right, 8px);

  @include min-at(sm) {
    padding: 0;

    flex-direction: column;
    align-items: center;

    @include gutter(right, 0);
  }

  &.highlighted {
    flex-flow: column nowrap;
    padding: 0 0 16px;

    @include min-at(sm) {
      flex-direction: row-reverse;
      padding: 0;

      > * {
        margin: 0;
      }
    }
  }
}

.news-card-wrapper {
  display: flex;
  flex-basis: 100%;

  @include min-at(sm) {
    & {
      margin-bottom: 16px;
    }
    &:not(:first-of-type) {
      flex-basis: calc(25% - 6px);
      flex-direction: column;
    }

    // 5, 9, 13, ...
    &:not(:nth-of-type(4n + 1)) {
      margin-right: 8px;
    }

    &:first-of-type {
      margin-bottom: 32px;
    }
  }

}

.news-list {
  width: calc(100% - 16px);
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 144px;

  display: flex;
  flex-flow: column nowrap;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background-image: $sunset-gradient;
  }

  @include min-at(sm) {
    width: calc(100% - 24px);
    flex-flow: row wrap;
    align-content: stretch;
  }
}
