.article-date {
  text-align: center;
  font-size: $font-sm;
  color: rgba($quite-black, 0.75);

  @include flex-center;
  @include gutter(right, 8px);

  // Adjust optical center
  > svg {
    margin-bottom: 2px;
  }
}

.article-hero {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  @include position-center;
}

.article-title {
  width: 100%;
  text-align: center;
  font-size: $font-lg;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: $very-white;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -16px);
  z-index: 1;
  opacity: 0.95;
  line-height: 1.5;
  text-shadow:
    0px 1px 0 rgba(black, 0.75);

  @include min-at(sm) {
    font-size: $font-xl;
    transform: translate(-50%, -24px);
    text-shadow:
      0px 1px 0 rgba(black, 0.5),
      1px 1px 0 rgba(black, 0.5),
      -1px 1px 0 rgba(black, 0.5);
  }

  @include min-at(md) {
    font-size: $font-xxl;
    transform: translate(-50%, -24px);
    text-shadow:
      0px 2px 0 rgba(black, 0.5),
      1px 2px 0 rgba(black, 0.5),
      -1px 2px 0 rgba(black, 0.5);
  }
}


.article-header {
  width: 100%;
  padding-top: 62.25%;
  position: relative;
  z-index: 1; // Create it's own stacking context

  @include min-at(sm) {
    padding-top: 52.25%;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2px;
    background-image: $sunset-gradient;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgba(black, 0.85), rgba(black, 0) 50%);
  }
}

.article-content {
  width: calc(100% - 32px);
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;

  > * {
    margin-bottom: 24px;
  }
}

.article-wrapper {
  width: calc(100% - 16px);
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  background-color: $very-white;
  padding-bottom: 32px;

  @include gutter(bottom, 32px);
}
