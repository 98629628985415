.movie-photo-thumb {
  width: 100%;

  transition: transform 0.15s ease-in;

}

.movie-photo-item {
  display: block;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $quite-black;
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    &::before {
      opacity: 0.35;
    }

    > .movie-photo-thumb {
      transform: scale(1.08);
      transition: transform 0.25s ease-out;
    }
  }
}

.movie-photo-list {
  width: 100%;

  @include gutter(bottom, 24px);

  @include min-at(sm) {
    column-count: 2;
  }

  @include min-at(md) {
    column-count: 3;
  }
}

.movie-photo-section {
  @extend .album-list-section;
}


.close-modal-button {
  width: 40px;
  height: 40px;

  position: fixed;
  right: 32px;
  top: 32px;

  background-color: transparent;
  border: none;
  @include flex-center;

  &:focus {
    outline: none;
  }

  &::before, &::after {
    content: '';
    display: block;
    width: 32px;
    height: 2px;
    border-radius: 2px;
    background-color: $very-white;

    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

}

.photo-full {
  max-width: 1024px;

  @include diffused-shadow(24px);
}

.photo-modal {
  @include flex-center;
  height: 100vh;
}

.photo-modal-container {
  width: 100%;
  height: 100vh;
  background-color: rgba($quite-black, 0.7);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 3;

  display: none;
}
