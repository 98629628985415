.slider-image {
  // Make it a bit larger than the container to prevent render problem
  // that the background is slightly revealed on the edge.
  width: 105%;
  height: auto;

  @include position-center;

  @include min-at(sm) {
    object-fit: cover;
    object-position: center;
    width: 105%;
    height: 105%;
  }

  @at-root {
    @keyframes Zoom {
      0% {
        transform: translate(-50%, -50%) scale(1);
      }
      100% {
        transform: translate(-50%, -50%) scale(1.08);
      }
    }
  }

  &.active {
    @include min-at(sm) {
      animation: Zoom 10s ease-out;
    }
  }
}

.slider-image-wrapper {
  display: block;
  width: 100%;
  padding-top: 62.5%;
  overflow: hidden;
  background-image: linear-gradient(to top right, $deep-grey, $oslo-grey);

  position: relative;

  @include min-at(sm) {
    flex-basis: 65%;
    flex-shrink: 0;
    padding-top: 52.25%;
  }
}

.slider-title {
  font-size: $font-lg;
  color: darken($deep-grey, 16%);
  line-height: 1.5;

  // 480px
  @include min-at(xs) {
    font-size: $font-xl;
  }

  @include min-at(md) {
    font-size: $font-xxl;
  }

  @include min-at(lg) {
    font-size: $font-xxxl;
  }
}

.slider-bar {
  width: 90%;
  height: 4px;
  background-image: $sunset-gradient;
  border-radius: 1px;
  transform: translateX(-10%);

  @include min-at(sm) {
    align-self: flex-start;
    transform: none;
    width: calc(100% + 100px);
  }

  @include min-at(md) {
    width: calc(100% + 120px);
  }
}

.slider-summary {
  font-size: $font-sm;
  color: $deep-grey;
  opacity: 0.9;

  @include min-at(md) {
    font-size: $font-md;
  }
  @include min-at(lg) {
    font-size: $font-lg;
  }
}

.slider-link {
  font-size: $font-sm;
  color: $deep-grey;

  display: flex;
  align-items: center;

  @include gutter(right, 4px)

  #svg-icon-arrow {
    transition: transform 0.15s ease-in;
  }

  > span {
    opacity: 0.8;
    transition: opacity 0.15s ease-in;
  }

  &:hover {
    #svg-icon-arrow {
      transform: translateX(4px);
      transition: transform 0.25s ease-out;
    }

    > span {
      opacity: 1;
      transition: opacity 0.25s ease-out;
    }
  }
}

.slider-text {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  @include gutter(bottom, 16px)

  @include min-at(lg) {
    @include gutter(bottom, 32px);
  }
}

.slider-slant {
  position: absolute;
  width: 100%;
  height: 32px;
  background-color: $very-white;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 95%);
  transform-origin: center bottom;
  transform:
    translateY(-95%)
    // The start corner of the clip-path isn't sharp and I don't know why
    // Therefore I use `scale` to let the corner overflow & hide.
    scale(1.05);
}

.slider-panel {
  background-color: $very-white;
  padding: 16px 8px;
  min-height: 240px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 32px;
    top: 0;
    left: 0;
    right: 0;
    background-color: $very-white;
    transform: skewY(2deg) translateY(-50%);
    z-index: 0;
  }

  @include gutter(bottom, 16px);

  @include min-at(sm) {
    flex-basis: 35%;
    min-height: 440px;
    padding-left: 16px;
    padding-top: 40px;

    &::after {
      left: auto;
      height: auto;
      width: 96px;
      bottom: 0;

      transform:
        skewX(-6deg)
        translateX(50%)
        translateY(0)
        scale(1.05);
    }
  }

  @include min-at(md) {
    padding-top: 10%;
    padding-bottom: 10%;
    min-height: 520px;
  }
}

.slider-previous, .slider-next {
  &::before, &::after {
    content: '';
    display: flex;
    position: absolute;
    width: 12px;
    height: 2px;
    border-radius: 2px;

    background-color: $oslo-grey;
    transition: background-color 0.15s ease-in;
  }

  &:hover {
    &::before, &::after {
      background-color: $spicy-red;
      transition: background-color 0.25s ease-out;
    }
  }
}

.slider-next {
  &::after {
    transform: rotate(90deg);
  }
}

.slider-previous,
.slider-next,
.slider-dot {
  position: relative;
  @include icon-button;
}

.slider-previous,
.slider-next {
  > svg {
    fill: $oslo-grey;
    transition: all 0.15s ease-in;
  }

  &:hover {
    > svg {
      fill: $spicy-red;
      transition: all 0.25s ease-out;
    }
  }
}

.slider-dot {
  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid $oslo-grey;
    border-radius: 50%;
    transition: all 0.15s ease-in;
  }

  &:hover {
    &::before {
      border-color: $spicy-red;
      transition: all 0.25s ease-out;
    }
  }

  &.active {
    @at-root {
      @keyframes Dot-Activated {
        50% {
          transform: scale(0);
          border: 0;
        }
        100% {
          transform: scale(1.5);
          border: 0;
          background-color: $spicy-red;
        }
      }
    }

    &::before {
      animation: Dot-Activated 0.25s ease-out forwards;
    }

  }

}

.slider-controller {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -8px);

  display: flex;
  align-items: center;


  @include gutter(right, 16px);
}

.slider {
  width: calc(100% - 16px);
  max-width: 1600px;
  margin: 0 auto;
  overflow: hidden;

  @include diffused-shadow(24px);

  @include min-at(sm) {
    display: flex;
    flex-flow: row-reverse nowrap;


  }

  @include min-at(md) {
    .slider-panel {
      padding-left: 32px;
    }
  }

  @include min-at(md) {
    .slider-image-wrapper {
      padding-top: 45%;
    }
  }
}
