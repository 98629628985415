.logo {
  width: 64px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-header {
  font-size: $font-md;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    background-color: $spicy-red;
    margin: 16px 0;
  }
}

.contact-label {
  font-family: $title-font;
  margin-bottom: 4px;
}

.footer-contact-item {

  > span {
    opacity: 0.8;
    margin-bottom: 8px;
  }

  &::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $spicy-red;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.footer-contact-list {
  @include gutter(bottom, 24px);
}

.footer-copyright {
  width: 100%;
  font-size: $font-xs;
  position: absolute;

  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);

}

footer {
  border-top: 2px solid $spicy-red;
  background-color: $very-white;
  padding: 48px 0 64px;
  text-align: center;

  margin-top: 128px;
  position: relative;


  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
