.column-1 {
  display: none;

  @include min-at(xl) {
    display: block;
    width: calc(100% / 8);
    left: 0;
  }
}

.column-2 {
  background-image: url('/img/pattern-red.jpg');
  background-size: contain;
  background-repeat: repeat-y;

  display: none;

  @include min-at(sm) {
    display: block;
    left: 0;
    width: calc(100% / 6);
  }

  @include min-at(xl) {
    width: calc(100% / 8);
    left: calc(100% / 8);
  }
}

.column-3 {
  display: none;

  @include min-at(xs) {
    display: block;
    left: 0;
    width: 25%;
  }

  @include min-at(sm) {
    width: calc(100% / 6);
    left: calc(100% / 6);
  }

  @include min-at(xl) {
    width: calc(100% / 8);
    left: calc(100% / 8 * 2);
  }
}

.column-4 {
  left: 0;
  width: 33.3%;

  @include min-at(xs) {
    display: block;
    left: 25%;
    width: 25%;
  }

  @include min-at(sm) {
    width: calc(100% / 6);
    left: calc(100% / 6 * 2);
  }

  @include min-at(xl) {
    width: calc(100% / 8);
    left: calc(100% / 8 * 3);
  }
}

.column-5 {
  left: 33.33%;
  width: 33.3%;

  @include min-at(xs) {
    display: block;
    left: 50%;
    width: 25%;
  }

  @include min-at(sm) {
    width: calc(100% / 6);
    left: calc(100% / 6 * 3);
  }

  @include min-at(xl) {
    width: calc(100% / 8);
    left: calc(100% / 8 * 4);
  }
}

.column-6 {
  left: 66.66%;
  width: 33.3%;

  background-image: url('/img/pattern-grey.jpg');
  background-size: contain;
  background-repeat: repeat-y;

  @include min-at(xs) {
    display: block;
    left: 75%;
    width: 25%;
  }

  @include min-at(sm) {
    width: calc(100% / 6);
    left: calc(100% / 6 * 4);
  }

  @include min-at(xl) {
    width: calc(100% / 8);
    left: calc(100% / 8 * 5);
  }
}

.column-7 {
  display: none;

  @include min-at(sm) {
    display: block;
    width: calc(100% / 6);
    left: calc(100% / 6 * 5);
  }

  @include min-at(xl) {
    width: calc(100% / 8);
    left: calc(100% / 8 * 6);
  }
}

.column-8 {
  display: none;

  @include min-at(xl) {
    display: block;
    width: calc(100% / 8);
    left: calc(100% / 8 * 7);
  }
}

.column {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: $bg-grey;
  box-shadow: -3px 0 8px rgba(0, 0, 0, 0.1);
}

.background-column {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background-color: $bg-grey;
  top: 0;
  left: 0;
}
