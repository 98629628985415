@import './partials/libraries/_bootstrap.scss';
@import './partials/libraries/_font-awesome.scss';
@import './partials/libraries/_normalize.scss';

// Project base
@import './partials/base/_colors.scss';
@import './partials/base/_layout.scss';
@import './partials/base/_media-queries.scss';
@import './partials/base/_typography.scss';
@import './partials/base/_style-presets.scss';

// Partials
// Global
@import './partials/_background-grid.scss';
@import './partials/_nav.scss';
@import './partials/_footer.scss';
@import './partials/_scroll-to-top.scss';

// Index
@import './partials/_slider.scss';
@import './partials/_section-title.scss';
@import './partials/_portfolio-card.scss';
@import './partials/_news-card.scss';

// Artist & movie page
@import './partials/_video.scss';
@import './partials/_info.scss';
@import './partials/_album-list.scss';
@import './partials/_photo-list.scss';


// Event page
@import './partials/_event.scss';


// Article
@import './partials/_article.scss';


// About
@import './partials/_about.scss';

// CMS
@import './partials/_cms.scss';
