.about-header {
  text-align: center;
  font-family: $title-font;

  h1 {
    font-size: $font-xl;
  }

  p {

  }

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    background-color: $spicy-red;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  > * {
    margin-bottom: 8px;
  }

  margin-bottom: 24px;
}

.about-content {
  > p {
    margin-bottom: 1.5em;
    max-width: 640px;
    opacity: 0.8;

    @include min-at(sm) {
      font-size: $font-md;
    }
  }

  img {
    display: block;
    width: calc(100% - 16px);
    max-width: 480px;
    margin: 0 auto 1em;

    @include min-at(sm) {
      width: calc(50% - 16px);
      margin: 0 auto 1em;
    }
  }

  figcaption {
    font-size: $font-sm;
    color: rgba($deep-grey, 0.75);

    &::before {
      content: '';
      display: block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: $spicy-red;
      margin-right: 4px;
    }

    display: flex;
    align-items: center;
  }

  figure {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 800px;

    @include min-at(sm) {
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.about-section {
  @extend .event-list;
  padding: 32px 16px;
}
