.cms-sidebar{
  li{
    padding:20px;
    border-bottom:solid 1px black;
    transition: background-color .3s;
  }
  li:hover{
    background-color:white;
  }
}
.panel{
  padding:20px;
}
.cms-row{
  // border-top:solid 1px #eee;
  border-bottom:solid 1px #eee;
  padding:10px 0;
  transition: background-color .3s;
}
.cms-img{
  width:100%;
}
.cms-textarea{
  // width:300px;
  height:100px;
}
.cms-row:hover{
  background-color:#eee;
}
