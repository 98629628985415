.nav-heading {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.nav-heading-image {
  max-width: 350px;
  width: 65%;
  height: auto;

  @include min-at(lg) {
    max-width: 420px;
  }
}

.nav-tagline {
  display: flex;
  align-items: center;
  font-family: $title-font;
  font-size: $font-sm;
  letter-spacing: 0.05em;

  @include min-at(lg) {
    font-size: $font-md;
  }

  &::before, &::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: $deep-grey;
    border-radius: 50%;
    margin: 0 16px;
  }
}

.nav-list-item {
  font-size: $font-sm;
  color: $deep-grey;

  position: relative;

  @include min-at(lg) {
    font-size: $font-lg;
  }


  &::after {
    content: '';
    display: block;
    width: 105%;
    height: 2px;
    position: absolute;
    bottom: -2px;
    background-color: $spicy-red;
    left: 50%;

    // Initial style
    transform: translateX(-50%) translateY(-200%);
    opacity: 0;

    transition: all 0.15s ease-in;
  }

  &:hover {
    opacity: 1 !important;
    transition: all 0.15s ease-in;

    &::after {
      // End style
      transform: translateX(-50%) translateY(0);
      opacity: 1;

      transition: all 0.25s ease-out;
    }
  }

}

.nav-list {
  width: 100%;
  max-width: 720px;
  padding: 0 16px;

  display: flex;
  justify-content: space-between;

  &:hover {
    .nav-list-item {
      opacity: 0.65;

      transition: all 0.25s ease-out;
    }
  }

  @include min-at(sm) {
    width: 66.6%;
  }
}

.nav-header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

nav {
  padding: 32px 0;
  margin-bottom: 24px;

  // Layout
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .nav-header {
    margin-bottom: 40px;
  }

}
