.artist-photo {
  position: absolute;
  width: 100%;
  top: -32px;
  left: -32px;
  width: calc(100% + 16px);

  @include diffused-shadow(24px);
}

.artist-photo-wrapper {
  grid-column-start: 1;
  grid-column-end: 2;
  width: 100%;
  // Expect the ratio to be 8 : 5
  padding-top: 62.5%;
  position: relative;
}

.info-title-main {
  font-size: $font-xl;
}

.info-title-sub {
  font-size: $font-md;
  opacity: 0.8;
  font-family: $title-font;
}

.info-summary-header {
  @include gutter(bottom, 8px);

  &::after {
    content: '';
    display: block;
    width: 80px;
    height: 2px;
    background-image: $sunset-gradient;
    margin-top: 16px;
  }
}

.info-description {
  font-size: $font-sm;
  > p {
    margin-bottom: 1.5em;
  }
}

.info-summary-section {
  grid-column-start: 2;
  grid-column-end: 3;
  @include gutter(bottom, 16px);
}

.bar-title {
  &::before {
    content: '';
    display: block;
    width: 2px;
    height: 20px;
    background-color: $spicy-red;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
  }

  > h2 {
    font-size: $font-md;
  }

  > p {
    font-size: $font-sm;
    font-family: $title-font;
    color: $oslo-grey;
  }

  display: flex;
  align-items: baseline;
  padding-left: 8px;
  position: relative;

  @include gutter(right, 8px);
}


.info-member-photo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.info-member-role {
  font-size: $font-xs;
  color: $oslo-grey;

  &::after {
    content: '';
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: $spicy-red;
    margin-left: 4px;
  }

  display: flex;
  align-items: center;
}

.info-member-social-media-icon {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}

.info-member-name {
  display: flex;
  align-items: center;
  @include gutter(right, 8px);
}

.info-member-item-wrapper {
  display: flex;
  align-items: center;
  @include gutter(right, 16px);
}

.info-member-item {
  display: flex;
  // flex-basis: 50%;
  flex-basis: 100%;
  max-width: 220px;
}

.info-member-list {
  display: flex;
  flex-flow: row wrap;

  > * {
    margin-bottom: 24px;
  }
}

.info-member-section {
  grid-column-start: 2;
  grid-column-end: 3;
  @include gutter(bottom, 16px);
}

.info-social-media-item {
  svg {
    width: 32px;
    height: 32px;
    fill: $deep-grey;
    opacity: 0.6;

    transition: opacity 0.15s ease-in;

    &:hover {
      opacity: 1;
      transition: opacity 0.25s ease-out;
    }
  }

  p {
    font-size: $font-sm;
  }

  > a {
    flex: none;
    min-width: 80px;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    @include gutter(bottom, 8px);
  }
}

.info-social-media-list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  @include gutter(bottom, 16px);
  @include gutter(right, 32px);
}

.info-social-media-section {
  @include gutter(bottom, 16px);
  grid-column-start: 2;
  grid-column-end: 3;
}

.info-event-item-title {
  color: currentColor;
  flex-basis: 30%;
  flex-shrink: 1;
}

.info-event-item-date,
.info-event-item-location {
  color: currentColor;
  flex-shrink: 0;

  display: flex;
  align-items: baseline;
  @include gutter(right, 8px);

  > svg {
    fill: currentColor;
    flex: none;

    // This is a magic number... because I want to align the
    // icon with the fitst line of the location
    transform: translateY(2px);
  }
}

.info-event-item-date {
  flex-basis: 25%;
}

.info-event-item-location {
  flex-basis: 45%;
}

.info-event-item {
  // Fade out text
  @for $i from 1 through 5 {
    &:nth-of-type(#{$i}) {
      color: rgba($deep-grey, 1 - 0.15 * ($i - 1));
    }
  }

  font-family: $title-font;
  text-transform: uppercase;
  position: relative;
  // Create stacking ocntext
  z-index: 0;

  display: flex;
  align-items: baseline;
  @include gutter(right, 8px);

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: -4px;
    bottom: -4px;
    left: -8px;
    right: -8px;
    background-color: $light-grey;
    border-radius: 2px;
  }

  &:nth-of-type(odd) {
    &::after {
      opacity: 0;
    }
  }
}

.info-event-list {
  @include gutter(bottom, 8px);
}

.info-event-section {
  @include gutter(bottom, 16px);

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
}


.artist-info-section {
  background-color: $very-white;
  padding: 16px;
  width: calc(100% - 32px);
  max-width: 1380px;
  transform: translateX(8px);

  margin-left: auto;
  margin-right: auto;

  // @include gutter(bottom, 24px);
  > * {
    margin-bottom: 24px;
  }

  @include min-at(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
}
@media(max-width:640px){
  .artist-info-section>*, .movie-info-section>*{
    margin-bottom:70px;
  }
}
// Movie page
.info-summary-section.movie {
  grid-column-start: 2;
  grid-column-end: 3;
}

.info-member-section.movie {
  grid-column-start: 2;
  grid-column-end: 3;

  // Tweak
  @include min-at(768px) {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  @include min-at(md) {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

.info-social-media-section.movie {
  grid-column-start: 2;
  grid-column-end: 3;

  // Tweak
  @include min-at(768px) {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  @include min-at(md) {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

.info-event-section.movie {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: auto;

  // Tweak
  @include min-at(768px) {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  @include min-at(md) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;

  }
}

.movie-poster {
  @extend .artist-photo;
}
.movie-poster-wrapper {
  @extend .artist-photo-wrapper;
  // padding-top: 160%;
}

.movie-info-section {
  @extend .artist-info-section;

  @include min-at(xs) {
    display: grid;
    grid-template-columns: 20% 1fr;
  }


  @include min-at(md) {
    grid-template-columns: 20% repeat(2, 1fr);
  }

}



