.portfolio-card-thumb {
  width: 100%;
  @include position-center;

  @include min-at(sm) {
    // slighly larger than wrapper to fill the white margin
    // between the container (browser rendering problem)?
    width: 105%;
    height: 105%;
    object-fit: cover;
  }

}

.portfolio-card-thumb-wrapper {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-top: 56%;
  -webkit-clip-path: polygon(0 0, 0 90%, 100% 100%, 100% 0);
  clip-path: polygon(0 0, 0 90%, 100% 100%, 100% 0);

  @include min-at(sm) {
    flex-basis: 65%;
    padding-top: 48%;
    transform-origin: left center;
    transform: scale(1.08);
    -webkit-clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 0);
  }
}

.portfolio-card-main {
  font-size: $font-lg;
  letter-spacing: 0.1em;

  @include min-at(xs) {
    font-size: $font-xl;
  }

  @include min-at(md) {
    font-size: $font-xxl;
  }
}

.portfolio-card-sub {
  font-size: $font-sm;
  font-family: $title-font;
  letter-spacing: 0.05em;
  opacity: 0.8;

  @include min-at(xs) {
    font-size: $font-md;
  }

  @include min-at(md) {
    font-size: $font-lg;
  }
}

.portfolio-card-header {
  > * {
    margin-bottom: 8px;

    @include min-at(md) {
      margin-bottom: 12px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-image: $sunset-gradient;
    transform: translateX(-16px);

    @include min-at(sm) {
      width: calc(100% + 8px);
      transform: translateX(8px);
    }
  }
}

.portfolio-card-description {
  opacity: 0.85;
  font-size: $font-sm;

  @include min-at(lg) {
    font-size: $font-md;
  }
}

.portfolio-card-link {
  @extend .slider-link;
  align-self: center;

  > svg {
    fill: $spicy-red;
  }

  @include min-at(sm) {
    align-self: flex-end;
    margin-top: auto;
  }
}

.portfolio-card-info {
  width: calc(100% - 32px);
  background-color: $very-white;
  padding: 48px 16px 16px;

  margin-top: -8%;

  display: flex;
  flex-flow: column nowrap;

  // Break latin words in the middle
  // in order to maintain container width
  word-break: break-all;

  @include gutter(bottom, 16px);

  @include min-at(sm) {
    flex-basis: 35%;

    margin-top: 0;
    padding-left: 64px;

  }
}

.portfolio-card {
  width: calc(100% - 16px);
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @include min-at(sm) {
    flex-direction: row;
    align-items: stretch;
  }
}

// Apply modifier for movie
.portfolio-card.movie {
  @include min-at(sm) {
    flex-direction: row-reverse;
  }

  .portfolio-card-thumb-wrapper {
    -webkit-clip-path: polygon(0 0, 0 100%, 100% 90%, 100% 0);
    clip-path: polygon(0 0, 0 100%, 100% 90%, 100% 0);

    @include min-at(sm) {
      -webkit-clip-path: polygon(0 0, 5% 100%, 100% 100%, 100% 0);
      clip-path: polygon(0 0, 5% 100%, 100% 100%, 100% 0);
      transform-origin: center right;
    }
  }

  .portfolio-card-header {
    text-align: right;

    &::after {
      transform: translateX(16px);
    }

    @include min-at(sm) {
      text-align: left;

      &::after {
        transform: translateX(-16px);
      }
    }

  }

  .portfolio-card-link {
    @include min-at(sm) {
      align-self: flex-start;
    }
  }

  .portfolio-card-info {
    @include min-at(sm) {
      padding-left: 16px;
      padding-right: 64px;
    }
  }
}

.portfolio-section {
  > * {
    margin-bottom: 80px;

    @include min-at(sm) {
      margin-bottom: 144px;
    }
  }
}
