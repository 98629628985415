.video-wrapper {
  width: calc(100% - 16px);
  max-width: 960px;
  height: 56.25vw;
  max-height: 540px;
  position: relative;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.video-section {
  width: 100%;
  height: 64vw;
  max-height: 720px;

  background-color: $quite-black;

  @include flex-center;

  margin-bottom: 96px;
}
