.scroll-to-top {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.45);
  border: 0;
  z-index: 5;
  border-radius: 0;

  @include flex-center;

  > svg {
    transform: rotate(-90deg);
    transition: transform 0.15s ease-in;
  }

  position: fixed;
  bottom: 48px;
  right: 48px;

  &:focus {
    outline: 0;
  }

  // Initial style
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in;


  &.active {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-out;
  }

  &:hover {
    > svg {
      transform: rotate(-90deg) translateX(4px);
      transition: transform 0.25s ease-out;
    }
  }
}
