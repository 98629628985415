$quite-black: hsl(0, 0, 2%);
$pure-white: #fff;
$very-white: hsl(0, 0, 99%);

// The Greys, datk to light
$deep-grey: hsl(206, 6%, 32%);
$oslo-grey: hsl(206, 6%, 54%);
$real-grey: hsl(206, 6%, 80%);
$light-grey: hsl(206, 6%, 90%);
$pale-grey: hsl(206, 6%, 96%);

$bg-grey: hsl(40, 2%, 78%);

// Accents
$spicy-red: #c9171e;


// Reference
$facebook-blue: #3b5998;


// Gradient
$sunset-gradient: linear-gradient(270deg, #FEB233 0%, #FE2A53 42%, #C9171E 97%);

/* Implementation
  ========================================================================== */
:root {
	--quite-black: $quite-black;
  --very-white: $very-white;
  --deep-grey: $deep-grey;
	--real-grey: $real-grey;
	--pale-grey: $pale-grey;
  --light-grey: $light-grey;
  --bg-grey: $bg-grey;
}

body {
	background-color: $pale-grey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $quite-black;
  @supports (--quite-black: $quite-black) {
    color: var(--quite-black);
  }
}

p {
  color: $deep-grey;
  @supports (--deep-grey: $deep-grey) {
    color: var(--deep-grey);
  }
}

::selection {
  color: white;
  background-color: red;
}
