.monthly-event-header {
  text-align: center;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @include gutter(bottom, 4px);

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 2px;
    background-color: $spicy-red;
    margin: 8px 0 16px;
  }

  > h2 {
    font-size: $font-lg;
    font-weight: 300;
    letter-spacing: 0.1em;
  }

  > p {
    font-family: $title-font;
    opacity: 0.75;
  }
}

.event-item-title {
  font-size: $font-md;
}

.event-member-item {
  font-size: $font-sm;
  color: $deep-grey;
  opacity: 0.8;

}

.event-member-list {
  display: flex;

  @include gutter(right, 16px);

}

.event-detail-item {
  display: flex;
  align-items: center;

  @include gutter(right, 8px);

}

.event-detail-list {
  display: flex;

  @include gutter(right, 32px);
}

.event-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @include gutter(bottom, 16px);

  &::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: $spicy-red;
    margin: 24px 0;
    transform: rotate(45deg);
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }

  > h3 {
    font-size: $font-xl;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}

.monthly-event-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &::after {
    content: '';
    display: block;
    width: 2px;
    height: 48px;
    background-color: $spicy-red;
    border-radius: 1px;

    margin: 24px 0;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}

.event-list {
  padding: 64px 0;
  width: calc(100% - 16px);
  max-width: 1024px;
  background-color: $very-white;
  margin-right: auto;
  margin-left: auto;

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;

    background-image: $sunset-gradient;
  }
}
